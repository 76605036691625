var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "mb-0 bold" }, [_vm._v("Monitor")]),
                  ]),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("h4", [_vm._v("Doctors")]),
              _c("p", [_vm._v("Select which doctor you would want to view")]),
              _c("b-table", {
                attrs: { items: _vm.items, fields: _vm.fields },
                scopedSlots: _vm._u([
                  {
                    key: "cell(action)",
                    fn: function (data) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "tw-capitalize tw-cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.onDoctorLogin(data.item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(data.value) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }