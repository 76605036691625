<template>
  <b-card>
    <template v-slot:header>
      <b-row align-v="center">
        <b-col>
          <h3 class="mb-0 bold">Monitor</h3>
        </b-col>
      </b-row>
    </template>

    <b-row>
      <b-col cols="12">
        <h4>Doctors</h4>
        <p>Select which doctor you would want to view</p>

        <b-table :items="items" :fields="fields">
          <template #cell(action)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <a
              @click="onDoctorLogin(data.item)"
              class="tw-capitalize tw-cursor-pointer"
            >
              {{ data.value }}
            </a>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "monitor",
  data() {
    return {
      fields: [
        {
          key: "doctor",
          sortable: true,
        },
        {
          key: "action",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  async created() {
    const doctors = await this.$store.dispatch("doctor/getDoctors");
    this.items = doctors
      .filter((doctor) => doctor.id !== this.user.id)
      .map((doctor) => ({
        doctor: `Dr. ${doctor.firstname} ${doctor.lastname}`,
        action: "login",
        id: doctor.id,
      }));
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    onDoctorLogin(data) {
      this.$store.dispatch("globals/setCurrentDoctor", {
        id: data.id,
        name: data.doctor,
      });
      window.location.href = "/";
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  color: #4f4f4f !important;
  font-size: 11px;
}

.card-header {
  border-bottom: 0;
  padding: 3rem 1.5rem;
}

.card-body {
  height: calc(100vh - 260px);
  overflow: auto;
}

@media (max-width: 768px) {
  .card-body {
    height: auto;
  }
}
</style>
